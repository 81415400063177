import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  HalfWidthBlock,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Top from "../../../images/reference/storge/top.jpg"
import Logo from "../../../images/reference/storge/logo-storge.png"
import Header from "../../../images/reference/storge/header-devel.png"
import Sideimg from "../../../images/reference/storge/screen-1.jpg"

class Storge extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(77,0,48,0.9),rgba(20,3,14,0.9)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Reference na nový web pro Storge | igloonet</title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//storge.cz"
          heading="Reference na nový web pro Storge"
          odstavec="2015"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Storge provozuje v Brně a Praze velkoobchody s květinami a floristickými doplňky. Dále také nabízí obchodním partnerům rozvoz svých produktů po celé ČR a části Slovenska."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p>
                Nahradit nevyhovující a špatně strukturované webové stránky za
                přehledné a responzivní řešení, které podpoří výkon online
                kampaně na propagaci velkoobchodu v Praze.
              </p>
            </PaddingWrapper>

            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <ul>
                <li>krátký průzkum trhu a návrh wireframů</li>
                <li>vytvoření vlastní grafiky</li>
                <li>
                  zpracování do HTML kódu s využitím moderní vektorové grafiky
                </li>
                <li>nasazení na redakční systém Drupal</li>
                <li>usnadnění výpisu volných pozic</li>
                <li>Facebook kampaň na propagaci pražského velkoobchodu</li>
              </ul>
            </PaddingWrapper>
          </HalfWidthBlock>

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Sideimg}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Spolupráce</TitleH2>
              <p>
                Celý projekt se nám podařilo dotáhnout do zdárného konce v
                rekordně krátkém čase, a to i díky výborné komunikaci s
                klientem. Dokázali jsme nový web úspěšně zbavit zastaralého
                obsahu a usnadnit zákazníkům vyhledávání nezbytných informací.
                Nyní jsou proto kontakty, sortiment i rozvozové trasy dostupné
                zcela intuitivně.
              </p>
              <p>
                Po dokončení zakázky jsme navíc pracovali na usnadnění výpisu
                aktuálních pracovních nabídek a úspěšné online kampani na
                podporu pražského velkoobchodu.
              </p>
            </BlockImageTextHalf>
          </PaddingWrapper>
        </ReferenceContainer>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default Storge
